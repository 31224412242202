<template>
  <editable-page v-if="publicProfile"
    :title="publicProfile && publicProfile.getHandle() ? publicProfile.getHandle() : name"
    :imageUrl="publicProfile.getPublicProfile() && publicProfile.getPublicProfile().getImageUrl()"
    :apiStatusList="[getPublicProfileStatus]"
    :edit="false"
  >
    <template v-slot:subtitle v-if="hasSubtitle">
      <v-row class="pl-5">
        <v-col>
          <stats-col title="Joined" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ publicProfile && publicProfile.getJoinedOn() && formatDate(publicProfile.getJoinedOn()) }}
          </stats-col>
        </v-col>
        <v-col>
          <stats-col title="Problems Solved" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ publicProfile && publicProfile.getPublicProfile() && publicProfile.getPublicProfile().getProblemsSolved() }}
          </stats-col>
        </v-col>
        <v-col>
          <stats-col title="Name" :cols="6" :md="3" :lg="2" class="font-weight-medium">
            {{ publicProfile && publicProfile.getName() }}
          </stats-col>
        </v-col>
        <v-col v-if="handle === userHandle">
          <stats-col title="">
            <v-btn class="mb-1" color="accent" @click="profileEditMode()" >Edit</v-btn>
          </stats-col>
        </v-col>
      </v-row>
    </template>

    <v-tabs color="accent" v-model="activeTab">
			<v-tab v-for="tab of tabs" :key="tab.index">
				{{tab.name}}
			</v-tab>
      <!-- <v-tab>Career</v-tab> -->
      <v-tab-item>
        <v-row>
          <v-col>
            <competitive-profile
              :competitiveProfiles="
                publicProfile &&
                  publicProfile.getPublicProfile() &&
                  publicProfile.getPublicProfile().getCompetitiveProfilesList()
              "
              :edit="false"
	      :isSelfProfile = "handle === userHandle"
            >
            </competitive-profile>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-card-title>Contests</v-card-title>
              <v-data-table
                class="px-5"
                :headers="headers"
                :items="publicProfile.getPublicProfile() && publicProfile.getPublicProfile().getContestStatsList()"
              >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <router-link  class="text-decoration-none router_link" v-if="item.getContentPreview()" :to="'/contests/' + item.getContentPreview().getUrl()" > {{ item.getContentPreview().getTitle() }} </router-link>
                    <span v-else> Unknown Contest </span>
                  </td>
                  <td>
                    <router-link  class="text-decoration-none router_link" v-if="item.getContentPreview()" :to="'/contests/' + item.getContentPreview().getUrl() + '/scoreboard'" > {{ item.getRank() }} </router-link>
                    <span v-else> {{ item.getRank() }}</span>
                  </td>
                  <td> {{ item.getProblemSolved() }}</td>
                </tr>
              </template>
              </v-data-table>

            </v-card>
          </v-col>
          </v-row>
      </v-tab-item>

      <!-- <v-tab-item>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-data-table
                no-data-text="No teams ceated."
                class="px-5"
                :headers="teamHeaders"
                :items="publicProfile.getTeamPreviewsList()"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <router-link  class="text-decoration-none router_link"
                        :to="`/contests/${item.getContestPreview().getUrl()}/teams/${item.getId()}`"
                      >
                        {{ item.getName() }}
                      </router-link>
                    </td>
                    <td>
                      <router-link class="text-decoration-none router_link"
                        v-if="item.getContestPreview()"
                        :to="
                          '/contests/' + item.getContestPreview().getUrl()"
                      >
                        {{ item.getContestPreview().getTitle() }}
                      </router-link>
                    </td>
                    <td>
                      <v-chip outlined color="grey accent" pill class="ma-1" v-for="(member, index) in item.getMemberPreviewsList()" :key="index">
                        <router-link v-if="member.getHandle()" :to="`/profiles/${member.getHandle()}`" class="text-decoration-none router_link">
		            {{member.getHandle()}}
                        </router-link>
			<span v-else>
			    {{member.getName()}}
			</span>
                      </v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item> -->
    </v-tabs>
  </editable-page>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import EditablePage from '../components/EditablePage';
import StatsCol from "../components/StatsCol";
import CompetitiveProfile from '../components/CompetitiveProfile';


export default {
  props:  {
		activeTab: {
			type: Number,
			required: false,
			default: 0,
		},
    userHandle: {
      type: String,
    },
  },
  data: function() {
    return {
	publicProfile: null,
      handle: null,
      editDone: false,
      	tabs: [
      	  { index: 0, name: 'Competitive' },
      	//   { index: 1, name: 'Teams' },
      ],
      headers: [
        {
          text: 'Contest',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Rank',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Problem solved',
          align: 'left'
        },
        ],
        teamHeaders: [
        {
          text: 'Team',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Contest',
          align: 'left',
          width: '20%',
        },
        {
          text: 'Team Members',
          align: 'left'
        },
			],

	};
  },
  methods: {
    ...mapActions( 'user',['getPublicProfile','register']),
    formatDate(date) {
      var d = new Date(date);
      var dd = String(d.getDate()).padStart(2, '0');
      var yyyy = d.getFullYear();
      var mmm= d.toLocaleString('default', { month: 'short' });
      d=dd + ' ' + mmm + ' ' + yyyy;
      return d;
     },
    profileEditMode() {
      this.$router.push('/profile/edit');
    },

  },
  computed: {
	...mapState( 'user',['getPublicProfileStatus','team',]),
  hasSubtitle() {
      return true;
    },
  name() {
      console.log('profile ', this.profile);
      if (this.publicProfile) return this.publicProfile.getName();
    },
  },
 mounted() {
    document.title= this.userHandle+" - CodeDrills";
    this.getPublicProfile({"handle": this.userHandle}).then(res => this.publicProfile = res);
    console.log("Public Profile ...", this.publicProfile);
    this.register().then(res => {
     this.handle = res.getUser().getHandle();
    });
},

  components: {
    StatsCol,
    EditablePage,
    CompetitiveProfile,
  },
};
</script>

<style scoped>
.router_link{
	color:inherit;
}
</style>
